export const useCockpitStore = defineStore('cockpit', () => {
  const apiCockpit = useApiCockpit()

  const items = ref(null)

  async function getCockpit() {
    const data = await apiCockpit.getCockpit()
    items.value = data
    return data
  }

  return {
    getCockpit,
    items,
  }
})
