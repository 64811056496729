export function useApiCockpit() {
  const client = useStrapiClient()

  async function getCockpit() {
    const data = await client('/cockpit', {
      method: 'GET',
    })
    return data
  }

  return {
    getCockpit,
  }
}
